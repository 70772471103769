import { excelParser } from '@/mixins/excelParser';
import { format } from 'date-fns';
export default {
  computed: {
    list() {
      return this.$store.state.moduleStack.list;
    },
    isLoading() {
      return this.$store.state.moduleStack.isLoading;
    },
    servers() {
      return this.$store.state.moduleStack.servers;
    },
    disks() {
      return this.$store.state.moduleStack.volumes;
    },
    quotaDiskCount() {
      return this.$store.getters['moduleStack/aviableDisks'];
    },
    flavors() {
      return this.$store.state.moduleStack.flavors;
    },
    networks() {
      return this.$store.state.moduleStack.networks;
    },
    project() {
      return this.$store.getters['moduleStack/project_id'];
    },
    bmId() {
      return this.$store.getters['moduleStack/GET_CURRENT']
        ? this.$store.getters['moduleStack/GET_CURRENT']
        : null;
    },
    images() {
      return this.$store.state.moduleStack.privateImages;
    },
    subnets() {
      return this.$store.state.moduleStack.subnets;
    },
    routers() {
      return this.$store.state.moduleStack.routers;
    },
    rules() {
      return this.$store.state.moduleStack.rules;
    },
  },
  methods: {
    fetch() {
      return this.$store.dispatch('moduleStack/fetchList');
    },
    newFetch() {
      this.isRequest = true;
      this.$store.state.moduleStack.isLoading = true;
      this.$store
        .dispatch('moduleStack/fetchOpenStackRequest', 'servers')
        .then(async () => {
          await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'projects');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/getTypesOfDisk');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchQuotasServer', this.project);
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchQuotasNetwork', this.project);
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchQuotasVolume', this.project);
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'secrets');
        })

        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'containers');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'volumes');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'floatingips');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'flavors');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'networks');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'images');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequestFirewalls', 'firewalls');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequestRules', 'rules');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'routers');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'ports');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'subnets');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOsKeypair');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchSnapshotsServer', this.project);
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'loadbalancers');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'healthmonitors');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'pools');
        })
        // .then(() => {
        //   this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'members');
        // })
        // .then(() => {
        //   this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'flavorprofiles'); //пока нет доступа
        // })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'listeners');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchPrivateImages', this.project);
        })
        .then(() => {
          this.$store.dispatch('moduleStack/getPublicNetworkPrefix', this.bmId);
        })
        .then(() => {
          this.$store.dispatch('moduleStack/getDnsNames', this.bmId);
        })
        .then(() => {
          this.$store.dispatch('moduleStack/getPrice', this.bmId);
        })
        .then(() => {
          this.$store.dispatch('moduleStack/getPublicNetworkPrefix', this.bmId);
        })
        .then(() => {
          this.$store.dispatch('moduleStack/getQuotas', this.bmId);
        })

        // this.$store.getters['moduleStack/GET_CURRENT']
        // .then(async () => {
        //   const payload = [this.servers, this.disks, 'disk'];
        //   await this.$store.dispatch('moduleStack/updateDiskServers', payload);
        // })
        // .then(async () => {
        //   const payload = [this.servers, this.flavors, 'flavor'];
        //   await this.$store.dispatch('moduleStack/updateFlavorsServers', payload);
        // })
        // .then(async () => {
        //   const payload = [this.servers, this.networks, 'networks'];
        //   await this.$store.dispatch('moduleStack/updateNetworksServers', payload);
        // })
        .then(() => {
          // console.log('complete');
          return true;
        })
        .catch(e => {
          console.error(e);
          console.log('error');
          this.isRequest = false;
          this.$store.state.moduleStack.isLoading = false;
        })
        .finally(() => {
          this.isRequest = false;
          this.$store.state.moduleStack.isLoading = false;
        });
    },
    fetchServer() {
      // this.isRequest = true;
      this.$store.state.moduleStack.serverRequest = true;
      this.$store
        .dispatch('moduleStack/fetchOpenStackRequest', 'servers')
        .then(async () => {
          await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'projects');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/getTypesOfDisk');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'volumes');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'floatingips');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'flavors');
        })

        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'networks');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequestFirewalls', 'firewalls');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequestRules', 'rules');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'images');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'routers');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'ports');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'subnets');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchOsKeypair');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchQuotasServer', this.project);
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchQuotasNetwork', this.project);
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchQuotasVolume', this.project);
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchSnapshotsServer', this.project);
        })
        .then(() => {
          this.$store.dispatch('moduleStack/fetchPrivateImages', this.project);
        })

        // .then(async () => {
        //   const payload = [this.servers, this.disks, 'disk'];
        //   await this.$store.dispatch('moduleStack/updateDiskServers', payload);
        // })
        // .then(async () => {
        //   const payload = [this.servers, this.flavors, 'flavor'];
        //   await this.$store.dispatch('moduleStack/updateFlavorsServers', payload);
        // })
        // .then(async () => {
        //   const payload = [this.servers, this.networks, 'networks'];
        //   await this.$store.dispatch('moduleStack/updateNetworksServers', payload);
        // })
        .then(() => {
          // console.log('fetchRequest');
          return true;
        })
        .catch(e => {
          console.error(e);
          // this.isRequest = false;
          this.$store.state.moduleStack.serverRequest = false;
        })
        .finally(() => {
          // this.isRequest = false;
          this.$store.state.moduleStack.serverRequest = false;
        });
    },
    async fetchNewBalancer() {
      // this.isRequest = true;
      this.$store.state.moduleStack.balancerRequest = true;
      await this.$store
        .dispatch('moduleStack/fetchOpenStackRequest', 'projects')
        .then(async () => {
          await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'servers');
        })
        .then(async () => {
          await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'floatingips');
        })
        .then(async () => {
          await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'networks');
        })
        .then(async () => {
          await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'routers');
        })
        .then(async () => {
          await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'ports');
        })
        .then(async () => {
          await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'subnets');
        })
        .then(() => {
          this.$store.dispatch('moduleStack/getPrice', this.bmId);
        })

        // .then(() => {
        //   console.log('fetchBalancerRequest');
        //   return true;
        // })
        .catch(e => {
          console.error(e);
          // this.isRequest = false;
          this.$store.state.moduleStack.balancerRequest = false;
        })
        .finally(() => {
          // this.isRequest = false;
          this.$store.state.moduleStack.balancerRequest = false;
          return true;
        });
    },
    isPublicNetwork(network) {
      return this.networks.find(x => x.name === network)
        ? this.networks.find(x => x.name === network).shared
        : false;
    },
    getListServersForPrint() {
      // console.log(this.servers);
      const serversForPrint = [];

      this.servers.forEach(server => {
        /* start network */
        const item = server.addresses;
        const keys = Object.keys(item);
        // const list = [];
        // let ip = '=';
        let text = '=';
        keys.forEach(key => {
          // console.log(key);
          // console.log(item[key]);
          item[key].forEach(ip => {
            // console.log(ip);
            this.isPublicNetwork(key)
              ? (text += `"public ${ip.addr}"&СИМВОЛ(10)&`)
              : (text += `"${key} ${ip.addr}"&СИМВОЛ(10)&`);
          });

          // this.isPublicNetwork(key)
          //   ? item[key].map(x => (text += `"public ${x.addr}"&СИМВОЛ(10)&`))
          //   : item[key].map(x => (text += `"${key} ${x.addr}"&СИМВОЛ(10)&`));
        });
        /* end network */

        /* start networkDisc */
        const volumeIds = [];
        let size = '=';
        let localNameOs = '';
        let metadata = {};
        let osName = '=';
        let osNameLocal = '';
        if (server['os-extended-volumes:volumes_attached'].length > 0) {
          server['os-extended-volumes:volumes_attached'].forEach(disk => {
            volumeIds.push(disk.id);
          });
          volumeIds.forEach(id => {
            const currentDisk = this.$store.state.moduleStack.volumes.find(x => x.id === id);
            metadata = Object.entries(currentDisk.metadata).length //&& currentDisk.bootable
              ? (osName +=
                  currentDisk.metadata && currentDisk.metadata.image_name
                    ? `"${currentDisk.metadata.image_name}"&СИМВОЛ(10)&`
                    : '')
              : (osName +=
                  currentDisk.volume_image_metadata && currentDisk.volume_image_metadata.image_name
                    ? `"${currentDisk.volume_image_metadata.image_name}"&СИМВОЛ(10)&`
                    : '');

            const prepareSize = currentDisk.size;
            const prepareType = currentDisk.volume_type;
            const name = currentDisk.name;
            size += `"${prepareType} - ${name ? name : id} - ${prepareSize} ГБ"&СИМВОЛ(10)&`;
            // currentDisk.size;
          });
        }
        if (server.flavor.disk > 0) {
          osNameLocal = this.$store.state.moduleStack.images.find(x => x.id === server.image.id)
            ? // return this.$store.state.moduleStack.images.find(x => x.id === this.item.image.id)
              this.$store.state.moduleStack.images.find(x => x.id === server.image.id).name
            : '---';
        }

        /* end networkDisk */

        serversForPrint.push({
          'Имя сервера': server.name,
          'Описание сервара': server.description,
          'Оперативная память': `${server.flavor.ram / 1024} ГБ`,
          'Количество процессоров': server.flavor.vcpus,
          'Локальный диск': server.flavor.disk > 0 ? `${server.flavor.disk} ГБ` : 'нет',
          'Сетевые диски': size.slice(0, -12),
          'Операционная система': osNameLocal ? osNameLocal : osName ? osName.slice(0, -12) : '',
          'Сеть - IP адрес': text.slice(0, -12),
          Статус: this.$t(`status.${server.status.key}`),
          Создан: format(server.created, 'dd.MM.yyyy HH:mm:ss'),
          'Последнее изменение': format(server.updated, 'dd.MM.yyyy HH:mm:ss'),
          // 'Операционная система': server.image,
          // 'Операционная система локального диска': osName ? osName.slice(0, -12) : '',
        });
      });
      excelParser().exportDataFromJSON(serversForPrint, null, null);
      // return serversForPrint;
    },
  },
};
